import React from 'react';
import '../../assets/styles/Footer.css'; // Import the Footer CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container mx-auto">
        <p className="mb-2">&copy; 2024 Company, Inc.</p>
        <div className="space-x-4">
          <a href="https://www.example.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
            Privacy Policy
          </a>
          <a href="https://www.example.com/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
            Terms & Conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
