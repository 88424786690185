import { iconMapper } from './iconMapper';

/**
 * Parses the JSON data and splits it into metadata, points, and linestrings.
 * @param {Object} data - The JSON data to be parsed.
 * @returns {Object} - An object containing metadata, points, and linestrings.
 */
export const parseGeoJsonData = (data) => {
  const { metadata, geojson } = data;

  const points = {
    type: 'FeatureCollection',
    features: geojson.features.filter(feature => feature.geometry.type === 'Point').map(feature => {
      const props = feature.properties;
      const displayData = props['display-data'] || {};
      const displayTextLang = props['display-text-lang'] || {};
      return {
        ...feature,
        properties: {
          ...props,
          icon: iconMapper(displayData.icon || ''),
          'marker-type': displayData['marker-type'] || 'marker',
          markdown: displayTextLang['card-story']?.en || ''
        }
      };
    })
  };

  const linestrings = {
    type: 'FeatureCollection',
    features: geojson.features.filter(feature => feature.geometry.type === 'LineString')
  };

  return {
    metadata,
    points,
    linestrings
  };
};
