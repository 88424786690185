/**
 * Fetches the list of walks from the local data folder.
 * @returns {Promise<Array>} - An array of walks with their uid and center coordinates.
 */
export const getWalks = async () => {
    try {
      const response = await fetch('/local_data_sync/walks.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching walks:', error);
      throw error;
    }
  };
  