import axios from 'axios';

/**
 * Fetches a local JSON file based on a given UID.
 * @param {string} uid - The unique identifier for the JSON file.
 * @returns {Promise<Object>} - The JSON content of the file.
 */
export const fetchLocalData = async (uid) => {
  try {
    const response = await fetch(`/local_data_sync/${uid}.json`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching local data:', error);
    throw error;
  }
};

/**
 * Queries an API to fetch a JSON object based on a given UID.
 * @param {string} uid - The unique identifier for the JSON object.
 * @returns {Promise<Object>} - The JSON content retrieved from the API.
 */
export const fetchApiData = async (uid) => {
  try {
    const response = await axios.get(`/api/data/${uid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching API data:', error);
    throw error;
  }
};
