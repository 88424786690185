import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import MapboxMap from './components/Map/MapboxMap';
import MaplibreMap from './components/Map/MaplibreMap';
import { MapWithLocalData, WalkSelector } from './components/DataSelectorBar/DataSelectorBar';
import InfoPanel from './components/InfoPanel/InfoPanel';
import MapTitle from './components/MapTitle/MapTitle';
import InformationStrip from './components/InformationStrip/InformationStrip';
import MarkdownPlan from './components/PlanPanel/MarkdownPlan'; // Update import path
import Footer from './components/Footer/Footer'; // Import the Footer component
import './assets/styles/App.css';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <button
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded m-2"
        onClick={() => navigate('/mapbox')}
      >
        Mapbox
      </button>
      <button
        className="bg-green-500 text-white font-bold py-2 px-4 rounded m-2"
        onClick={() => navigate('/maplibre')}
      >
        Maplibre
      </button>
    </div>
  );
};

const App = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [metadata, setMetadata] = useState({});
  const [points, setPoints] = useState({});

  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const stadiaMapsApiKey = process.env.REACT_APP_STADIA_MAPS_API_KEY;

  const defaultCenter = [2.17, 41.38];
  const defaultZoom = 10;

  const handleMarkerClick = (markerInfo) => {
    console.log('Marker clicked:', markerInfo); // Debug log
    setSelectedMarker(markerInfo);
  };

  const resetInfoPanel = () => {
    setSelectedMarker(null);
  };

  return (
    <div className="flex flex-col min-h-screen relative">
      <Router>
        <WalkSelector setMapType={() => {}} setSelectedWalk={() => {}} resetInfoPanel={resetInfoPanel} />
        <MapTitle title={metadata.name} />
        <InformationStrip distance={metadata.distance} steps={metadata.steps} grade={metadata.grade} />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/mapbox"
              element={
                mapboxToken ? (
                  <MapboxMap
                    center={defaultCenter}
                    zoom={defaultZoom}
                    mapboxToken={mapboxToken}
                    onMarkerClick={handleMarkerClick}
                  />
                ) : (
                  <div>Mapbox token is missing</div>
                )
              }
            />
            <Route
              path="/maplibre"
              element={
                <MaplibreMap
                  center={defaultCenter}
                  zoom={defaultZoom}
                  stadiaMapsApiKey={stadiaMapsApiKey}
                  onMarkerClick={handleMarkerClick}
                />
              }
            />
            <Route
              path="/mapbox/local/:uid"
              element={
                <MapWithLocalData
                  mapType="mapbox"
                  center={defaultCenter}
                  zoom={defaultZoom}
                  mapboxToken={mapboxToken}
                  onMarkerClick={handleMarkerClick}
                  setMetadata={setMetadata}
                  setPoints={setPoints}
                />
              }
            />
            <Route
              path="/maplibre/local/:uid"
              element={
                <MapWithLocalData
                  mapType="maplibre"
                  center={defaultCenter}
                  zoom={defaultZoom}
                  stadiaMapsApiKey={stadiaMapsApiKey}
                  onMarkerClick={handleMarkerClick}
                  setMetadata={setMetadata}
                  setPoints={setPoints}
                />
              }
            />
          </Routes>
        </div>
      </Router>
      <InfoPanel selectedMarker={selectedMarker} />
      <MarkdownPlan points={points} />
      <Footer /> {/* Use Footer component */}
    </div>
  );
};

export default App;
