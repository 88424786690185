// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox GL JS CSS
import 'maplibre-gl/dist/maplibre-gl.css'; // Import Maplibre GL JS CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
