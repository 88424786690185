import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import MapboxMap from '../Map/MapboxMap';
import MaplibreMap from '../Map/MaplibreMap';
import { fetchLocalData } from '../../data/dataConnect';
import { parseGeoJsonData } from '../../data/dataParser';
import { getWalks } from '../../data/getWalks';
import { computeCentroidAndZoom } from '../../data/mapUtils';

export const MapWithLocalData = ({ mapType, mapboxToken, stadiaMapsApiKey, onMarkerClick, setMetadata, setPoints }) => {
  const { uid } = useParams();
  const [metadata, setMetadataState] = useState(null);
  const [points, setPointsState] = useState(null);
  const [linestrings, setLinestrings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState([2.17, 41.38]);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const localData = await fetchLocalData(uid, { signal: controller.signal });
        const parsedData = parseGeoJsonData(localData);
        console.log(parsedData);
        setMetadataState(parsedData.metadata);
        setMetadata(parsedData.metadata);
        setPointsState(parsedData.points);
        setPoints(parsedData.points);
        setLinestrings(parsedData.linestrings);

        const { centroid, zoom } = computeCentroidAndZoom(parsedData.points, parsedData.linestrings);
        setCenter(centroid);
        setZoom(zoom);

        setLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching or parsing data:', error);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [uid, setMetadata, setPoints]);

  if (loading) return <div>Loading data...</div>;

  const MapComponent = mapType === 'mapbox' ? MapboxMap : MaplibreMap;

  return (
    <MapComponent
      center={center}
      zoom={zoom}
      mapboxToken={mapboxToken}
      stadiaMapsApiKey={stadiaMapsApiKey}
      onMarkerClick={onMarkerClick}
      metadata={metadata}
      points={points}
      linestrings={linestrings}
      lineAsDots={true}
    />
  );
};

export const WalkSelector = ({ setMapType, setSelectedWalk, resetInfoPanel }) => {
  const [walks, setWalks] = useState([]);
  const [selectedMapType, setSelectedMapType] = useState('mapbox');
  const [selectedUid, setSelectedUid] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchWalks = async () => {
      try {
        const walksData = await getWalks();
        setWalks(walksData);
        if (walksData.length > 0) {
          setSelectedUid(walksData[0].uid || walksData[0].name);
        }
      } catch (error) {
        console.error('Error fetching walks:', error);
      }
    };

    fetchWalks();
  }, []);

  const handleMapTypeChange = (event) => {
    const mapType = event.target.value;
    setSelectedMapType(mapType);
    setMapType(mapType);
    if (selectedUid) {
      navigate(`/${mapType}/local/${selectedUid}`);
    }
    resetInfoPanel();
  };

  const handleUidChange = (event) => {
    const uid = event.target.value;
    setSelectedUid(uid);
    setSelectedWalk(uid);
    navigate(`/${selectedMapType}/local/${uid}`);
    resetInfoPanel();
  };

  useEffect(() => {
    if (location.pathname === '/') return;
    const pathSegments = location.pathname.split('/');
    const currentMapType = pathSegments[1];
    const currentUid = pathSegments[3];

    if (currentMapType && currentUid) {
      setSelectedMapType(currentMapType);
      setSelectedUid(currentUid);
      setMapType(currentMapType);
      setSelectedWalk(currentUid);
    }
  }, [location.pathname, setMapType, setSelectedWalk]);

  return (
    <div className="sticky top-0 z-20 bg-indigo-400 text-white p-1 flex justify-end space-x-2 text-sm">
      <select
        value={selectedMapType}
        onChange={handleMapTypeChange}
        className="bg-gray-300 text-black p-1 rounded text-xs"
      >
        <option value="mapbox">Mapbox</option>
        <option value="maplibre">Maplibre</option>
      </select>
      <select
        value={selectedUid}
        onChange={handleUidChange}
        className="bg-gray-300 text-black p-1 rounded text-xs"
      >
        {walks.map((walk) => (
          <option key={walk.uid || walk.name} value={walk.uid || walk.name}>
            {walk.uid || walk.name}
          </option>
        ))}
      </select>
    </div>
  );
};
