export const getCurrentLocation = (onLocationUpdate) => {
    if ('geolocation' in navigator) {
      const updateLocation = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            console.log(`Current position: Latitude: ${latitude}, Longitude: ${longitude}`);
            if (onLocationUpdate) {
              onLocationUpdate({ latitude, longitude });
            }
          },
          (error) => {
            console.error('Error getting geolocation:', error);
          },
          {
            enableHighAccuracy: true,
            timeout: 10000
          }
        );
      };
  
      updateLocation(); // Call immediately
      const intervalId = setInterval(updateLocation, 10000); // Repeat every 10 seconds
  
      return () => clearInterval(intervalId); // Cleanup interval on unmount
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };
  