import React, { useRef, useEffect, useState, useCallback } from 'react';
import AnimateRandom from './AnimateRandom';
import CardBox from './CardBox';
import { createMarkerElement } from '../../data/markerMaker'; // Corrected import path
import { iconMapper } from '../../data/iconMapper'; // Import iconMapper
import animationPuffinBlink from './local_animations/puffin_blink.json';
import animationPuffinFrontBlink from './local_animations/puffin_front_blink.json';
import animationPuffinFrontPoint from './local_animations/puffin-front-point.json';
import '../../assets/styles/PlanPanel.css'; // Import the new CSS file
import blueStars from './local_icons/blue_stars.svg'; 
import goldStar from './local_icons/gold_star.svg'; 
import goldSparkles from './local_icons/gold_sparkles.svg'; 
import greenTalkBubble from './local_icons/green-talk-bubble.svg'; 
import messageAlert from './local_icons/talk-bubbles.svg'; 
import testIcon from './local_icons/test-icon.svg';


const animations = [animationPuffinBlink, animationPuffinFrontBlink, animationPuffinFrontPoint];

const getIconComponent = (alert_icon) => {
  console.log("-> >", alert_icon);
  switch (alert_icon) {
    case 'animation':
      return <AnimateRandom icons={animations} />;
    case 'blue-stars':
      return <img src={blueStars} alt="Gem Icon" className="icon-container" />;
    case 'gold-star':
      return <img src={goldStar} alt="Another Icon" className="icon-container" />;
    case 'gold-sparkles':
      return <img src={goldSparkles} alt="Another Icon" className="icon-container" />;
    case 'green-talk-bubble':
      return <img src={greenTalkBubble} alt="Another Icon" className="icon-container" />;
    case 'test-icon':
      return <img src={testIcon} alt="Another Icon" className="icon-container" />;
    case 'message-alert':
      return <img src={messageAlert} alt="Another Icon" className="icon-container" />;
    default:
      return <img src={blueStars} alt="Default Icon" className="icon-container" />;
  }
};

const PlanPanel = ({ data, lang = 'en' }) => {
  const iconRefs = useRef([]);
  const panelRef = useRef(null);
  const [lineSegments, setLineSegments] = useState([]);

  const updateLineSegments = useCallback(() => {
    if (!panelRef.current) return;

    const segments = [];
    const panelRect = panelRef.current.getBoundingClientRect();

    for (let i = 0; i < iconRefs.current.length - 1; i++) {
      const iconA = iconRefs.current[i];
      const iconB = iconRefs.current[i + 1];

      if (iconA && iconB) {
        const y1 = iconA.getBoundingClientRect().top - panelRect.top + iconA.clientHeight / 2;
        const y2 = iconB.getBoundingClientRect().top - panelRect.top + iconB.clientHeight / 2;
        const x1 = iconA.getBoundingClientRect().left - panelRect.left + iconA.clientWidth / 2;
        const x2 = iconB.getBoundingClientRect().left - panelRect.left + iconB.clientWidth / 2;

        segments.push({ x1, y1, x2, y2, dist: data[i + 1]?.properties?.['display-text-units']?.['card-dist-from-previous']?.["met"] || '' });
      }
    }
    setLineSegments(segments);
  }, [data]);

  useEffect(() => {
    updateLineSegments();
    window.addEventListener('resize', updateLineSegments);
    window.addEventListener('scroll', updateLineSegments);
    return () => {
      window.removeEventListener('resize', updateLineSegments);
      window.removeEventListener('scroll', updateLineSegments);
    };
  }, [updateLineSegments]);

  useEffect(() => {
    setTimeout(updateLineSegments, 0); // Ensure lines are updated after DOM changes
  }, [data, updateLineSegments]);

  return (
    <div className="plan-panel-container">
      <div ref={panelRef} className="plan-panel-inner">
        <svg className="svg-overlay" style={{ zIndex: 1 }}>
          {lineSegments.map((segment, index) => (
            <React.Fragment key={index}>
              <line
                x1={segment.x1}
                y1={segment.y1}
                x2={segment.x2}
                y2={segment.y2}
                style={{ stroke: 'gray', strokeWidth: 2 }}
              />
              <text
                x={(segment.x1 + segment.x2) / 2 - 5} // Adjust the position to the left of the line
                y={(segment.y1 + segment.y2) / 2 - 5} // Adjust the vertical position
                fill="black"
                fontSize="9"
                textAnchor="end" // Right-align the text
              >
                {segment.dist}
              </text>
            </React.Fragment>
          ))}
        </svg>
        <header className="plan-header">
          <h1>Explore</h1>
        </header>
        {data.map((place, index) => {
          const properties = place.properties || {};
          const displayData = properties['display-data'] || {};
          const displayUnits = properties['display-text-units'] || {};
          const displayTextLang = properties['display-text-lang'] || {};

          const stage = {
            icon: iconMapper(displayData.icon || ''), // Use iconMapper to get the correct icon class
            label: displayTextLang['card-label'] ? displayTextLang['card-label'][lang] : '',
            description: displayTextLang['card-story'] ? displayTextLang['card-story'][lang] : '',
            flashtips: displayTextLang['card-todo'] ? displayTextLang['card-todo'][lang] : '',
            dist: displayUnits['card-dist-from-previous']?.["met"] || '',
            alert_icon: displayData['card-alert-icon'] ? displayData['card-alert-icon'] : "gem",
            category: displayTextLang['card-category'] ? displayTextLang['card-category']["en"] : "",
          };

          const markerElement = createMarkerElement(stage.icon, displayData['marker-type']);

          return (
            <div key={index} className="card" style={{ zIndex: 1 }}>
              <div className="card-col-1" style={{ zIndex: 1 }}>
                <div
                  ref={el => iconRefs.current[index] = el}
                  className="marker-container"
                  dangerouslySetInnerHTML={{ __html: markerElement.outerHTML }}
                ></div>
              </div>
              <CardBox
                place_display={stage}
                animatedIcon={getIconComponent(stage.alert_icon)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlanPanel;
