export const createMarkerElement = (icon, markerType) => {
    const el = document.createElement('div');
    el.className = markerType || 'marker';
  
    if (icon) {
      el.innerHTML = `<i class="${icon}"></i>`;
    } else {
      el.innerHTML = '<div class="marker-number">1</div>';
    }
  
    return el;
  };
  