import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

const AnimateRandom = ({ icons, interval = 3000 }) => {
  const [currentIcon, setCurrentIcon] = useState(icons[0]);

  useEffect(() => {
    if (icons.length > 1) {
      const switchIcon = () => {
        const randomIndex = Math.floor(Math.random() * icons.length);
        setCurrentIcon(icons[randomIndex]);
      };
      const iconInterval = setInterval(switchIcon, interval);
      return () => clearInterval(iconInterval);
    }
  }, [icons, interval]);

  if (icons.length === 1) {
    return icons[0];
  }

  if (typeof currentIcon === 'string') {
    return <img src={currentIcon} alt="Icon" />;
  }

  return <Lottie options={{ loop: true, autoplay: true, animationData: currentIcon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} height={36} width={36} />;
};

export default AnimateRandom;
