import React, { useEffect, useRef } from 'react';
import maplibregl from 'maplibre-gl';
import { addMaplibreControls } from './MapControls';
import { linestringsToDotsZoom } from '../../data/mapUtils';
import { createMarkerElement } from '../../data/markerMaker';
import { getCurrentLocation } from './getCurrentLocation'; // Import the geolocation function
import './MapStyles.css'; // Import the CSS file

const MaplibreMap = ({ center, zoom, stadiaMapsApiKey, onMarkerClick, metadata, points, linestrings }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const onMarkerClickRef = useRef(onMarkerClick);
  onMarkerClickRef.current = onMarkerClick;

  useEffect(() => {
    let isMounted = true;

    // Initialize the map
    mapRef.current = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json?api_key=${stadiaMapsApiKey}`,
      center: center,
      zoom: zoom,
      pitch: 0,
      bearing: 0,
      dragRotate: true,
      touchPitch: true,
    });

    const map = mapRef.current;

    map.on('load', () => {
      if (!isMounted) return;

      addMaplibreControls(map, center, zoom);

      if (points) {
        points.features.forEach((feature) => {
          const { coordinates } = feature.geometry;
          const { icon, 'marker-type': markerType } = feature.properties;
          const el = createMarkerElement(icon, markerType);

          new maplibregl.Marker({ element: el })
            .setLngLat(coordinates)
            .addTo(map)
            .getElement()
            .addEventListener('click', () => {
              if (isMounted) {
                onMarkerClickRef.current(feature.properties);
              }
            });
        });
      }

      const updateDotsLayer = () => {
        const currentZoom = map.getZoom();
        linestringsToDotsZoom(map, linestrings, currentZoom);
      };

      if (linestrings) {
        updateDotsLayer();
        map.on('zoomend', updateDotsLayer);
      }
    });

    return () => {
      isMounted = false;
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [center, zoom, stadiaMapsApiKey, points, linestrings]);

  useEffect(() => {
    const stopLocationUpdates = getCurrentLocation();
    return () => stopLocationUpdates();
  }, []);

  return <div ref={mapContainerRef} className="w-full h-full min-h-[400px]" />;
};

export default MaplibreMap;
