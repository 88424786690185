import React from 'react';

const InformationStrip = ({ distance, steps, grade }) => {
  return (
    <div className="sticky top-[3rem] z-10 bg-gray-700 text-white p-2 text-xs flex space-x-4">
      <div><strong>Distance:</strong> {distance}</div>
      <div><strong>Steps:</strong> {steps}</div>
      <div><strong>Grade:</strong> {grade}°</div>
    </div>
  );
};

export default InformationStrip;
