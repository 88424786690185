/**
 * Utility functions for generic map-related tasks that are not dependent on Mapbox or Maplibre.
 */

/**
 * Converts degrees to radians.
 * @param {number} degrees - The degree value to be converted.
 * @returns {number} - The converted radians value.
 */
export const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };
  
  /**
   * Calculates the distance between two coordinates using the Haversine formula.
   * @param {Object} coord1 - The first coordinate { lat: number, lng: number }.
   * @param {Object} coord2 - The second coordinate { lat: number, lng: number }.
   * @returns {number} - The distance between the two coordinates in kilometers.
   */
  export const calculateDistance = (coord1, coord2) => {
    const earthRadiusKm = 6371;
  
    const dLat = degreesToRadians(coord2.lat - coord1.lat);
    const dLng = degreesToRadians(coord2.lng - coord1.lng);
  
    const lat1 = degreesToRadians(coord1.lat);
    const lat2 = degreesToRadians(coord2.lat);
  
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    return earthRadiusKm * c;
  };
  
  /**
   * Computes the centroid of the given point and linestring features and calculates the zoom level.
   * @param {Object} points - GeoJSON FeatureCollection of Point features.
   * @param {Object} linestrings - GeoJSON FeatureCollection of LineString features.
   * @returns {Object} - An object containing the centroid coordinates and the zoom level.
   */
  export const computeCentroidAndZoom = (points, linestrings) => {
    const coordinates = [];
  
    points.features.forEach((feature) => {
      coordinates.push(feature.geometry.coordinates);
    });
  
    linestrings.features.forEach((feature) => {
      feature.geometry.coordinates.forEach((coord) => {
        coordinates.push(coord);
      });
    });
  
    const centroid = coordinates.reduce(
      (acc, coord) => {
        acc[0] += coord[0];
        acc[1] += coord[1];
        return acc;
      },
      [0, 0]
    );
  
    centroid[0] /= coordinates.length;
    centroid[1] /= coordinates.length;
  
    let maxDistance = 0;
  
    coordinates.forEach((coord) => {
      const distance = calculateDistance(
        { lat: centroid[1], lng: centroid[0] },
        { lat: coord[1], lng: coord[0] }
      );
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    });
  
    const zoom = Math.max(0, 14 - Math.log(maxDistance + 1) / Math.LN2);
  
    return { centroid, zoom };
  };
  
  /**
   * Converts a GeoJSON FeatureCollection of LineString features to a GeoJSON FeatureCollection of Point features.
   * @param {Object} linestrings - GeoJSON FeatureCollection of LineString features.
   * @param {number} gap - The distance between points in kilometers.
   * @returns {Object} - GeoJSON FeatureCollection of Point features.
   */
  export const linestringToDots_deprecated = (linestrings, gap) => {
    const pointsArray = [];
  
    linestrings.features.forEach((feature) => {
      const coordinates = feature.geometry.coordinates;
  
      for (let i = 0; i < coordinates.length - 1; i++) {
        const start = coordinates[i];
        const end = coordinates[i + 1];
  
        pointsArray.push(start);
  
        const segmentLength = calculateDistance(
          { lat: start[1], lng: start[0] },
          { lat: end[1], lng: end[0] }
        );
  
        const dotCount = Math.floor(segmentLength / gap);
  
        for (let m = 1; m <= dotCount; m++) {
          const fraction = (m * gap) / segmentLength;
          const dotLon = start[0] + fraction * (end[0] - start[0]);
          const dotLat = start[1] + fraction * (end[1] - start[1]);
  
          pointsArray.push([dotLon, dotLat]);
        }
      }
    });
  
    const pointFeatures = pointsArray.map((coord) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: coord,
      },
      properties: {},
    }));
  
    return {
      type: 'FeatureCollection',
      features: pointFeatures,
    };
  };

/**
 * Converts a GeoJSON FeatureCollection of LineString features to a GeoJSON FeatureCollection of Point features
 * and updates the map with the resulting points or lines.
 * The gap between points is determined smoothly based on the zoom level.
 * @param {Object} map - The map object to update.
 * @param {Object} linestrings - GeoJSON FeatureCollection of LineString features.
 * @param {number} zoom - The zoom level.
 */
export const linestringsToDotsZoom = (map, linestrings, zoom) => {
    const pointsArray = [];
    console.log("zoom level ->", zoom)
    let gap;
    if (zoom > 18) {
      gap = 0.002;
    } else if (zoom > 16) {
      gap = 0.002 + (0.005 - 0.002) * (18 - zoom) / 2; // Interpolate between 0.015 and 0.04
    } else if (zoom > 14) {
      gap = 0.005 + (0.002 - 0.001) * (16 - zoom) / 2; // Interpolate between 0.04 and 0.08
    } else {
      // Display lines directly if zoom is less than 11
      if (map.getSource('linestrings')) {
        map.getSource('linestrings').setData(linestrings);
      } else {
        map.addSource('linestrings', {
          type: 'geojson',
          data: linestrings,
        });
        map.addLayer({
          id: 'linestrings',
          type: 'line',
          source: 'linestrings',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#0ea5e9',
            'line-width': 5,
          },
        });
      }
      // Remove dots layer if present
      if (map.getLayer('dots')) {
        map.removeLayer('dots');
        map.removeSource('dots');
      }
      return;
    }
  
    // Remove linestrings layer if present
    if (map.getLayer('linestrings')) {
      map.removeLayer('linestrings');
      map.removeSource('linestrings');
    }
  
    linestrings.features.forEach((feature) => {
      const coordinates = feature.geometry.coordinates;
  
      for (let i = 0; i < coordinates.length - 1; i++) {
        const start = coordinates[i];
        const end = coordinates[i + 1];
  
        pointsArray.push(start);
  
        const segmentLength = calculateDistance(
          { lat: start[1], lng: start[0] },
          { lat: end[1], lng: end[0] }
        );
  
        const dotCount = Math.floor(segmentLength / gap);
  
        for (let m = 1; m <= dotCount; m++) {
          const fraction = (m * gap) / segmentLength;
          const dotLon = start[0] + fraction * (end[0] - start[0]);
          const dotLat = start[1] + fraction * (end[1] - start[1]);
  
          pointsArray.push([dotLon, dotLat]);
        }
      }
    });
  
    const pointFeatures = pointsArray.map((coord) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: coord,
      },
      properties: {},
    }));
  
    const dots = {
      type: 'FeatureCollection',
      features: pointFeatures,
    };
  
    if (map.getSource('dots')) {
      map.getSource('dots').setData(dots);
    } else {
      map.addSource('dots', {
        type: 'geojson',
        data: dots,
      });
      map.addLayer({
        id: 'dots',
        type: 'circle',
        source: 'dots',
        paint: {
          'circle-radius': 3,
          // 'circle-color': '#f97316',
          'circle-color': '#0ea5e9',
        },
      });
    }
  };
  
  
  