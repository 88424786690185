import React, { useEffect, useState } from 'react';
import PlanPanel from './PlanPanel';
import './MarkdownPlan.css';

const MarkdownPlan = ({ points }) => {
  const [placesDisplay, setPlacesDisplay] = useState([]);

  useEffect(() => {
    if (points.features && points.features.length > 0) {
      setPlacesDisplay(points.features);
    } else {
      fetch('/places_display.json')
        .then((response) => response.json())
        .then((data) => {
          if (data && data.length > 0) {
            setPlacesDisplay(data);
          }
        })
        .catch((error) => {
          console.error('Error fetching places_display.json:', error);
        });
    }
  }, [points]);

  return (
    <PlanPanel data={placesDisplay} lang="en" />
  );
};

export default MarkdownPlan;
