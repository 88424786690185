// src/components/InfoPanel.js
import React, { useEffect, useState } from 'react';
import './InfoPanel.css';

const InfoPanel = ({ selectedMarker }) => {
  const [placeholderImage, setPlaceholderImage] = useState('');
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    if (selectedMarker) {
      setPlaceholderImage(`https://picsum.photos/200?random=${Math.random()}`);
      setIsMinimized(false); // Reset to expanded when a new marker is selected
    }
  }, [selectedMarker]);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const isExpanded = selectedMarker && selectedMarker.sitename && !isMinimized;

  return (
    <div className={`infopanel ${isExpanded ? 'expanded' : ''} ${isMinimized ? 'minimized' : ''}`}>
      {selectedMarker && (
        <>
          <div className="infopanel-info-section">
            <div className="infopanel-content">
              <div className="infopanel-title">
                {selectedMarker.sitename || 'No Title Available'}
              </div>
              <div className="infopanel-category">
                {selectedMarker?.['display-text-lang']?.['card-category']?.['en'] || ''}
              </div>
            </div>
            {!isMinimized && (
              <>
                <div className="infopanel-shortdesc">
                  <strong>{selectedMarker?.['display-text-lang']?.['shortdesc']?.['en'] || 'No Description Available'}</strong>
                </div>
                <div className="infopanel-hours">
                  <strong></strong> {selectedMarker?.['display-text-lang']?.['card-hours']?.['en'] || 'No Open Hours Available'}
                </div>
                <div className="infopanel-description">
                  {selectedMarker?.['display-text-lang']?.['locals-guide']?.['en'] || 'No Description Available'}
                </div>
                <div className="infopanel-links">
                  {selectedMarker?.['display-data']?.['card-gmaps'] && (
                    <div className="infopanel-link">
                      <strong></strong> <a href={selectedMarker['display-data']['card-gmaps']} target="_blank" rel="noopener noreferrer">Map on Google</a>
                    </div>
                  )}
                  {selectedMarker?.['display-data']?.['card-url'] && (
                    <div className="infopanel-link">
                      <strong></strong> <a href={selectedMarker['display-data']['card-url']} target="_blank" rel="noopener noreferrer">Visit Website</a>
                    </div>
                  )}
                </div>
                {selectedMarker.audioEmbed && (
                  <div className="infopanel-audio" dangerouslySetInnerHTML={{ __html: selectedMarker.audioEmbed }} />
                )}
              </>
            )}
          </div>
          {!isMinimized && (
            <div className="infopanel-image-section">
              {selectedMarker.image ? (
                <img src={selectedMarker.image} alt={selectedMarker.description || 'Marker'} className="infopanel-image" />
              ) : (
                <img src={placeholderImage} alt="Placeholder" className="infopanel-image" />
              )}
            </div>
          )}
          <div className="infopanel-icon" onClick={toggleMinimize}>
            {isMinimized ? '↗' : '↙'}
          </div>
        </>
      )}
    </div>
  );
};

export default InfoPanel;
