import React, { useEffect, useRef, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { addMapboxControls } from './MapControls';
import { linestringsToDotsZoom } from '../../data/mapUtils';
import { createMarkerElement } from '../../data/markerMaker';
import { getCurrentLocation } from './getCurrentLocation'; // Import the geolocation function
import './MapStyles.css'; // Import the CSS file

const MapboxMap = ({ center, zoom, mapboxToken, onMarkerClick, metadata, points, linestrings }) => {
  const mapContainerRef = useRef(null);
  const onMarkerClickRef = useRef(onMarkerClick);
  onMarkerClickRef.current = onMarkerClick;

  const createNewMap = useCallback((center, zoom) => {
    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center,
      zoom: zoom,
      pitch: 0,
      bearing: 0,
      dragRotate: true,
      touchPitch: true,
    });

    newMap.on('load', () => {
      addMapboxControls(newMap, center, zoom);

      if (points) {
        points.features.forEach((feature) => {
          const { coordinates } = feature.geometry;
          const { icon, 'marker-type': markerType } = feature.properties;
          const el = createMarkerElement(icon, markerType);

          new mapboxgl.Marker(el)
            .setLngLat(coordinates)
            .addTo(newMap)
            .getElement()
            .addEventListener('click', () => {
              console.log('Marker clicked:', feature.properties);
              onMarkerClickRef.current(feature.properties);
            });
        });
      }

      const updateDotsLayer = () => {
        const currentZoom = newMap.getZoom();
        linestringsToDotsZoom(newMap, linestrings, currentZoom);
      };

      if (linestrings) {
        updateDotsLayer();
        newMap.on('zoomend', updateDotsLayer);
      }
    });

    newMap.on('click', (e) => {
      const features = newMap.queryRenderedFeatures(e.point);
      if (features.length) {
        const feature = features[0];
        if (feature.layer.id === 'points') {
          console.log('Marker clicked:', feature.properties);
          onMarkerClickRef.current(feature.properties);
        }
      }
    });

    return newMap;
  }, [points, linestrings]);

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken;
    const initializedMap = createNewMap(center, zoom);

    return () => {
      initializedMap.remove();
    };
  }, [center, zoom, mapboxToken, createNewMap]);

  useEffect(() => {
    const stopLocationUpdates = getCurrentLocation();
    return () => stopLocationUpdates();
  }, []);

  return <div ref={mapContainerRef} className="w-full h-full min-h-[400px]" />;
};

export default MapboxMap;
