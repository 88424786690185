import mapboxgl from 'mapbox-gl';
import maplibregl from 'maplibre-gl';

class GoHomeControl {
  constructor(center, zoom) {
    this.center = center;
    this.zoom = zoom;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML = '<i class="fas fa-home"></i>';
    this.button.onclick = () => {
      this.map.flyTo({ center: this.center, zoom: this.zoom });
    };
    this.container.appendChild(this.button);
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

class MaximizeControl {
  constructor() {
    this.isMaximized = false;
    this.originalStyle = {};
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML = '<i class="fas fa-expand"></i>';
    this.button.onclick = () => {
      if (this.isMaximized) {
        this.restoreMap();
      } else {
        this.maximizeMap();
      }
      this.map.resize();
    };
    this.container.appendChild(this.button);
    return this.container;
  }

  maximizeMap() {
    this.originalStyle = {
      width: this.map.getContainer().style.width,
      height: this.map.getContainer().style.height,
      position: this.map.getContainer().style.position,
      top: this.map.getContainer().style.top,
      left: this.map.getContainer().style.left,
      zIndex: this.map.getContainer().style.zIndex,
    };

    this.map.getContainer().style.position = 'fixed';
    this.map.getContainer().style.top = '0';
    this.map.getContainer().style.left = '0';
    this.map.getContainer().style.width = '100%';
    this.map.getContainer().style.height = '100%';
    this.map.getContainer().style.zIndex = '9999';

    this.button.innerHTML = '<i class="fas fa-compress"></i>';
    this.isMaximized = true;
    this.map.resize();
  }

  restoreMap() {
    this.map.getContainer().style.width = this.originalStyle.width;
    this.map.getContainer().style.height = this.originalStyle.height;
    this.map.getContainer().style.position = this.originalStyle.position;
    this.map.getContainer().style.top = this.originalStyle.top;
    this.map.getContainer().style.left = this.originalStyle.left;
    this.map.getContainer().style.zIndex = this.originalStyle.zIndex;

    this.button.innerHTML = '<i class="fas fa-expand"></i>';
    this.isMaximized = false;
    this.map.resize();
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export const addMapboxControls = (map, center, zoom) => {
  const scale = new mapboxgl.ScaleControl({
    maxWidth: 80,
    unit: 'metric',
  });
  map.addControl(scale);

  const nav = new mapboxgl.NavigationControl({
    showCompass: true,
    visualizePitch: true,
  });
  map.addControl(nav, 'top-right');

  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserHeading: true,
  });
  map.addControl(geolocate, 'top-left');

  const goHomeControl = new GoHomeControl(center, zoom);
  map.addControl(goHomeControl, 'top-left');

  const maximizeControl = new MaximizeControl();
  map.addControl(maximizeControl, 'top-right');
};

export const addMaplibreControls = (map, center, zoom) => {
  const scale = new maplibregl.ScaleControl({
    maxWidth: 80,
    unit: 'metric',
  });
  map.addControl(scale);

  const nav = new maplibregl.NavigationControl({
    showCompass: true,
    visualizePitch: true,
  });
  map.addControl(nav, 'top-right');

  const geolocate = new maplibregl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserHeading: true,
  });
  map.addControl(geolocate, 'top-left');

  const goHomeControl = new GoHomeControl(center, zoom);
  map.addControl(goHomeControl, 'top-left');

  const maximizeControl = new MaximizeControl();
  map.addControl(maximizeControl, 'top-right');
};
