export const iconMapper = (icon) => {
    const iconMapping = {
      faRoute: 'fa fa-route',
      faRestaurant: 'fa fa-utensils',
      faCoffee: 'fa fa-coffee',
      faBridge: 'fa fa-bridge',
      faRiver: 'fa fa-water',
      faMuseum: 'fa fa-building-columns',
      faPark: 'fa fa-tree',
      faChurch: 'fa fa-church',
      faShop: 'fa fa-shop',
      faHotel: 'fa fa-hotel',
      faStar: 'fa fa-star',
      faTrain: 'fa fa-train',
      faBus: 'fa fa-bus',
      faTram: 'fa fa-train-tram',
      faBurn: 'fa fa-burn',
      faMountain: 'fa fa-mountain',
      faWalk: 'fa fa-walking',
      faPaintBrush: "fa-palette",
      faCocktail: "fa fa-martini-glass-citrus",
      faSculpture: "fa fa-monument",
      faWineBottle: "fa fa-wine-bottle",
      faMonument: "fa fa-monument",
      faTheatre: "fa fa-masks-theater",
      faFlag: "fa fa-flag",
    };
  
    return iconMapping[icon] || 'fa fa-star';
  };
  