import React from 'react';
import FlashTips from './FlashTips';
import './Card.css'; // Import the card-specific CSS

const CardBox = ({ place_display, animatedIcon }) => (
  <div className="card-content card-content-col-2">
    {place_display.flashtips && (
      <FlashTips 
        animatedIcon={animatedIcon} 
        tipText={<span dangerouslySetInnerHTML={{ __html: place_display.flashtips }} />} 
        cssClass="my-custom-class" 
      />
    )}
    {/* <h3 className="text-xl font-semibold" dangerouslySetInnerHTML={{ __html: place_display.label }}></h3> */}
    <div className="place-display">
      <h3 className="text-xl font-semibold label" dangerouslySetInnerHTML={{ __html: place_display.label }}></h3>
      <span className="text-sm font-semibold category" dangerouslySetInnerHTML={{ __html: place_display.category }}></span>
    </div>    
    <p dangerouslySetInnerHTML={{ __html: place_display.description }}></p>
  </div>
);

export default CardBox;
