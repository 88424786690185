import React from 'react';
import './FlashTips.css'; // Import the flash tips-specific CSS

const FlashTips = ({ animatedIcon, tipText, cssClass }) => (
  <div className={`flash-tips ${cssClass}`}>
    <div className="flash-tips-icon">
      {animatedIcon}
    </div>
    <span className="flash-tips-text">{tipText}</span>
  </div>
);

export default FlashTips;
