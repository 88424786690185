import React from 'react';

const MapTitle = ({ title }) => {
  return (
    <div className="sticky top-[1rem] z-10 bg-gray-800 text-slate-100 p-2 font-bold">
      {title}
    </div>
  );
};

export default MapTitle;
